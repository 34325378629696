import React, { useEffect, useState } from 'react';
import { supabase } from './supabaseClient';
import IdeaCard from './IdeaCard';
import { useAuth } from './AuthContext';

// Helper function to generate an array of placeholder ideas
const generatePlaceholderIdeas = (count) => {
  const placeholderNames = [
    "Nice try, champ!",
    "Almost insightful!",
    "Keep at it, tiger.",
    "Not quite there yet!",
    "Dream big, buddy!",
    "Getting warmer!",
    "Oh, you're close!",
    "Better luck next time!",
    "Keep digging, detective!",
    "On the right track!",
    "Good hustle, pal!",
    "Almost genius!",
    "Creative... kinda.",
    "Solid attempt!",
    "Close, but not quite!",
    "Nice one, Sherlock!",
    "A+ for effort!",
    "Imaginative, almost!",
    "So close, yet so far!",
    "Good try, big dog.",
  ];

  return Array.from({ length: count }, (_, i) => ({
    id: `placeholder-${i}`,
    idea: placeholderNames[Math.floor(Math.random() * placeholderNames.length)],
    context_summary: 'Sign in to view detailed insights!',
    difficulty: Math.floor(Math.random() * 10) + 1,
    category: ':)',
    timestamp: '00:20:00',
    video_url: '',
    channel_name: "Hidden Channel",
    channel_image: '', // Placeholder image is blank
  }));
};

// Helper function to shuffle an array
const shuffleArray = (array) => {
  return array
    .map((item) => ({ ...item, sortKey: Math.random() }))
    .sort((a, b) => a.sortKey - b.sortKey)
    .map(({ sortKey, ...rest }) => rest);
};

const IdeasList = () => {
  const { session, signInWithGoogle } = useAuth();
  const [categories, setCategories] = useState([]);
  const [ideas, setIdeas] = useState([]);
  const [filteredIdeas, setFilteredIdeas] = useState([]);
  const [activeCategory, setActiveCategory] = useState('All');
  const [channels, setChannels] = useState([]);
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [sortAscending, setSortAscending] = useState(null);
  const [showSignInPrompt, setShowSignInPrompt] = useState(false);

  // Placeholder data for users who are not signed in
  const placeholderIdeas = generatePlaceholderIdeas(6);

  useEffect(() => {
    if (!session) {
      console.log('3', activeCategory, selectedChannels.length, activeCategory !== 'All' || selectedChannels.length !== 0)
      setShowSignInPrompt(activeCategory !== 'All' || selectedChannels.length !== 0);
    }
  }, [session, activeCategory, selectedChannels])

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const { data, error } = await supabase
          .from('ideas')
          .select('category');

        if (error) throw error;

        let uniqueCategories = ['All', ...new Set(data.map(item => item.category))];

        // Move "Other" to the end of the array if it exists
        uniqueCategories = uniqueCategories.filter(cat => cat !== 'Other');
        uniqueCategories.push('Other');

        setCategories(uniqueCategories);
      } catch (error) {
        console.error('Error fetching categories:', error);
        setCategories(['All']); // Default to "All" if there's an error
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchIdeas = async () => {
      const { data, error } = await supabase
        .from('ideas')
        .select(`
          id, idea, context_summary, category, timestamp, difficulty,
          podcast_analyses ( video_url, podcast_title, channels ( name, profile_picture_url, id ) )
        `);

      if (error) {
        console.error('Error fetching ideas:', error);
      } else {
        const formattedIdeas = data.map(idea => ({
          ...idea,
          video_url: idea.podcast_analyses.video_url,
          podcast_title: idea.podcast_analyses.podcast_title, // Include podcast title
          channel_name: idea.podcast_analyses.channels.name,
          channel_image: idea.podcast_analyses.channels.profile_picture_url,
          channel_id: idea.podcast_analyses.channels.id,
        }));

        const shuffledIdeas = shuffleArray(formattedIdeas);
        setIdeas(shuffledIdeas);
        setFilteredIdeas(shuffledIdeas);

        const uniqueChannels = Array.from(
          new Map(shuffledIdeas.map(idea => [idea.channel_id, {
            id: idea.channel_id,
            name: idea.channel_name,
            image: idea.channel_image,
          }])).values()
        );
        setChannels(uniqueChannels);
      }
    };

    fetchIdeas();
  }, []);

  const handleCategoryChange = (category) => {
    setActiveCategory(category);
    const categoryFilteredIdeas = category === 'All' ? ideas : ideas.filter(idea => idea.category === category);
    setFilteredIdeas(shuffleArray(categoryFilteredIdeas)); // Randomize filtered ideas
  };

  const handleSortByDifficulty = () => {
    const sortedIdeas = [...filteredIdeas].sort((a, b) =>
      sortAscending ? a.difficulty - b.difficulty : b.difficulty - a.difficulty
    );
    setFilteredIdeas(sortedIdeas);
    setSortAscending(!sortAscending);
  };

  const toggleChannelSelection = (channelId) => {

    const updatedSelection = selectedChannels.includes(channelId)
      ? selectedChannels.filter(id => id !== channelId)
      : [...selectedChannels, channelId];
    setSelectedChannels(updatedSelection);

    const channelFilteredIdeas = updatedSelection.length === 0 ? ideas : ideas.filter(idea => updatedSelection.includes(idea.channel_id));
    setFilteredIdeas(shuffleArray(channelFilteredIdeas)); // Randomize filtered ideas

  };

  return (
    <div className="container mx-auto p-4">
      {/* Navbar for category filtering */}
      <div className="navbar bg-base-200 rounded-lg p-4 mb-6 overflow-x-auto whitespace-nowrap">
        {categories.map((category) => (
          <button
            key={category}
            className={`btn mr-1 btn-ghost ${activeCategory === category ? 'btn-active' : ''}`}
            onClick={() => handleCategoryChange(category)}
          >
            {category}
          </button>
        ))}
      </div>

      {/* Channel filtering buttons */}
      <div className="flex space-x-4 mb-6 overflow-x-auto">
        {channels.map((channel) => (
          <button
            key={channel.id}
            className={`btn btn-outline ${selectedChannels.includes(channel.id) ? 'btn-active' : ''}`}
            onClick={() => toggleChannelSelection(channel.id)}
          >
            <img
              src={channel.image}
              alt={channel.name}
              className="w-6 h-6 rounded-full mr-2"
            />
            {channel.name}
          </button>
        ))}
      </div>

      {/* Sort button */}
      <div className="flex items-center mb-6">
        <button
          className={`btn ${sortAscending === null ? 'btn-outline' : 'btn-primary'}`}
          onClick={handleSortByDifficulty}
        >
          Sort by Difficulty {sortAscending === true ? '↑' : sortAscending === false ? '↓' : ''}
        </button>
        <span className="text-gray-600 text-sm ml-4">
          {filteredIdeas.length} {filteredIdeas.length === 1 ? 'Idea' : 'Ideas'}
        </span>
      </div>

      {/* Sign-in prompt overlay when channels are selected while signed out */}
      {showSignInPrompt && (
        <div className="relative">
          <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 opacity-20 filter blur-sm">
            {placeholderIdeas.map((idea) => (
              <IdeaCard key={idea.id} idea={idea} />
            ))}
          </div>

          {/* Sign In Prompt Overlay */}
          <div className="absolute inset-0 flex items-start justify-center">
            <div className="bg-white p-6 rounded-lg shadow-lg text-center">
              <h2 className="text-lg font-bold mb-4">Sign in to get more specific insights!</h2>
              <button onClick={() => signInWithGoogle()} className="btn btn-primary">
                Sign In with Google
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Display filtered ideas when user is signed in or no channel filter is applied */}
      {((session || selectedChannels.length === 0) && !showSignInPrompt) && (
        <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {filteredIdeas.map((idea) => (
            <IdeaCard key={idea.id} idea={idea} />
          ))}
        </div>
      )}
    </div>
  );
};

export default IdeasList;
