// Pricing.js
import React from 'react';

const Pricing = () => {
  return (
    <div className="container mx-auto p-6">
      <h1 className="text-4xl font-bold text-center mb-8">Pricing Options</h1>
      <p className="text-center text-lg mb-12">
        I’m hustling too! Help me out, and I’ll keep the ideas coming.
      </p>

      {/* Responsive container with flex and grid for responsive layout */}
      <div className="flex flex-col lg:flex-row gap-10 justify-center items-center lg:items-start">
        
        {/* Standard Plan Section */}
        <div className="card max-w-md bg-gradient-to-r from-indigo-500 to-blue-500 text-white shadow-xl mb-8 lg:mb-0 h-full">
          <div className="card-body p-8 h-full flex flex-col justify-between">
            <div>
              <h2 className="card-title text-3xl font-bold">Standard Plan: $1/month</h2>
              <p className="mt-4">Stay in the know with a premium newsletter that gets you ahead.</p>
              <ul className="list-disc list-inside mt-7 space-y-2">
                <li><strong>Weekly Idea Digest:</strong> Your regular newsletter with the freshest business ideas from your favourite youtubers.</li>
                <li><strong>Extra Premium Content:</strong> Every idea analyzed with an MVP feature list, a guide to validation and SEO keyword research done for you</li>
                <li><strong>Early Access:</strong> Be among the first to see the ideas from the experts, before your competition.</li>
              </ul>
              <p className="italic mt-4">
                “The smartest dollar you’ll spend all month. A simple way to stay inspired, keep tabs on trends, and get those ideas flowing.”
              </p>
            </div>
            <button className="btn btn-accent w-full mt-6">Join for $1/Month</button>
          </div>
        </div>

        {/* Premium Credits Section */}
        <div className="card max-w-xl bg-white text-gray-800 shadow-xl h-full">
          <div className="card-body p-8 h-full flex flex-col justify-between">
            <div>
              <h2 className="card-title text-3xl font-bold text-indigo-700">Premium Credits</h2>
              <p className="mt-4">
                Unlock the full toolkit without a monthly commitment.
              </p>
              <ul className="list-disc list-inside mt-4 space-y-2">
                <li><strong>Pay Once, Use Anytime:</strong> Perfect if you want flexibility—use credits only when you need that extra boost.</li>
                <li><strong>Advanced Idea Pack:</strong> Includes keyword research, MVP feature lists, and step-by-step guides to validation.</li>
              </ul>
              <table className="table w-full mt-6">
                <thead>
                  <tr className="text-black">
                    <th className="text-left">Credits</th>
                    <th className="text-left">Cost</th>
                    <th className="text-left">Savings</th>
                    <th className="text-center">Select</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>10</td>
                    <td>$5</td>
                    <td>--</td>
                    <td className='flex justify-center'>
                      <button className="btn btn-outline btn-sm text-black">Buy 10</button>
                    </td>
                  </tr>
                  <tr>
                    <td>20</td>
                    <td>$9</td>
                    <td>Save 10%</td>
                    <td className='flex justify-center'>
                      <button className="btn btn-outline btn-sm text-black">Buy 20</button>
                    </td>
                  </tr>
                  <tr>
                    <td>50</td>
                    <td>$20</td>
                    <td>Save 20%</td>
                    <td className='flex justify-center'>
                      <button className="btn btn-outline btn-sm text-black">Buy 50</button>
                    </td>
                  </tr>
                  <tr>
                    <td>100</td>
                    <td>$35</td>
                    <td>Save 30%</td>
                    <td className='flex justify-center'>
                      <button className="btn btn-outline btn-sm text-black">Buy 100</button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p className="text-center text-xs text-gray-700">1 credit = 1 idea expanded with validation plan and SEO keywords</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
