import React, { useState } from 'react';
import { supabase } from './supabaseClient';

const IdeaCard = ({ idea }) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const isLocalhost = window.location.hostname === 'localhost';

  const formatTimestamp = (timestamp) => {
    const [hours, minutes, seconds] = timestamp.split(':').map(Number);
    const totalSeconds = (hours * 3600) + (minutes * 60) + seconds;
    const formattedHours = Math.floor(totalSeconds / 3600);
    const formattedMinutes = Math.floor((totalSeconds % 3600) / 60);
    const formattedSeconds = totalSeconds % 60;

    return formattedHours > 0
      ? `${formattedHours}:${String(formattedMinutes).padStart(2, '0')}:${String(formattedSeconds).padStart(2, '0')}`
      : `${formattedMinutes}:${String(formattedSeconds).padStart(2, '0')}`;
  };

  const generateTimestampLink = (videoUrl, timestamp) => {
    const [hours, minutes, seconds] = timestamp.split(':').map(Number);
    const totalSeconds = (hours * 3600) + (minutes * 60) + seconds;
    return `${videoUrl}&t=${totalSeconds}s`;
  };

  // Decode HTML entities in the title
  const decodeHtmlEntities = (text) => {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = text;
    return textArea.value;
  };

  // Decode and truncate the podcast title
  const truncateTitle = (title) => {
    const decodedTitle = decodeHtmlEntities(title);
    return decodedTitle.length > 40 ? `${decodedTitle.slice(0, 40)}...` : decodedTitle;
  };

  const handleDelete = async () => {
    try {
      await supabase.from('ideas').delete().eq('id', idea.id);
      setShowConfirm(false);
    } catch (error) {
      console.error('Error deleting idea:', error);
    }
  };

  return (
    <div className="card bg-white shadow-md rounded-lg p-4 text-black relative">
      <div className="flex items-center mb-2">
        {idea.channel_image && (
          <img
            src={idea.channel_image}
            alt={idea.channel_name}
            className="w-8 h-8 rounded-full mr-2"
          />
        )}
        <h3 className="text-lg font-bold">{idea.idea}</h3>
      </div>

      {/* Video Title with Truncated Podcast Title and Timestamp Link */}
      {idea.video_url && idea.timestamp && (
        <a
          href={generateTimestampLink(idea.video_url, idea.timestamp)}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 text-sm mb-2 inline-block"
        >
          {truncateTitle(idea.podcast_title)} – {formatTimestamp(idea.timestamp)}
        </a>
      )}

      <p className="text-sm mb-2"><strong>Difficulty:</strong> {idea.difficulty}/10</p>
      <p className="text-sm mb-4"><strong>Category:</strong> {idea.category}</p>

      {/* Display context_summary as plain text */}
      <p className="text-sm mb-4 max-w-96">{idea.context_summary}</p>

      {isLocalhost && (
        <button
          className="absolute top-2 right-2 text-red-500 hover:text-red-700"
          onClick={() => setShowConfirm(true)}
        >
          Delete
        </button>
      )}

      {showConfirm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <p className="text-lg font-bold mb-4">Are you sure you want to delete this idea?</p>
            <div className="flex justify-center space-x-4">
              <button className="btn btn-primary" onClick={handleDelete}>
                Yes, Delete
              </button>
              <button className="btn btn-secondary" onClick={() => setShowConfirm(false)}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default IdeaCard;
