import React from 'react';

const Terms = () => {
  return (
    <div className="p-6 bg-base-100 text-base-content rounded-lg shadow-lg">
      <h1 className="text-2xl font-bold mb-4">Terms of Service for Ideas Inbound</h1>
      <p className="mb-2">*Effective Date: November 13, 2024*</p>
      <p className="mb-4">
        Welcome to Ideas Inbound (http://ideasinbound.com/). By purchasing a membership or using our service, you agree
        to these Terms of Service. Please read them carefully.
      </p>

      <h2 className="text-lg font-semibold mb-2">1. Service Overview</h2>
      <p className="mb-4">
        When you purchase a membership with Ideas Inbound, you will receive curated emails containing business ideas
        from your selected creators whenever they release new content. This service is intended solely for personal use,
        and you may not resell, redistribute, or commercially exploit the content provided by Ideas Inbound.
      </p>

      <h2 className="text-lg font-semibold mb-2">2. Refund Policy</h2>
      <p className="mb-4">
        You are eligible for a full refund within 7 days of purchase. To request a refund, please contact us via our
        email listed below.
      </p>

      <h2 className="text-lg font-semibold mb-2">3. Updates to the Terms</h2>
      <p className="mb-4">
        We may occasionally update these Terms of Service. If we make any changes, we will notify users by email.
      </p>

      <h2 className="text-lg font-semibold mb-2">4. Governing Law</h2>
      <p className="mb-4">
        These Terms of Service are governed by the laws of Australia. By using our service, you agree to submit to the
        jurisdiction of the Australian courts.
      </p>

      <h2 className="text-lg font-semibold mb-2">5. Contact Information</h2>
      <p className="mb-4">
        If you have any questions about these Terms of Service, please contact us at <a href="mailto:40willobrien@gmail.com" className="text-primary underline">40willobrien@gmail.com</a>.
      </p>

      <p>Thank you for choosing Ideas Inbound.</p>
    </div>
  );
};

export default Terms;
