// EmailCapture.js
import React, { useState } from 'react';
import { supabase } from './supabaseClient';
import { Link } from 'react-router-dom';

const EmailCapture = ({ setEmailSubmitted, message, setMessage }) => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage(''); // Reset message before each submission

    try {
      const { error } = await supabase.from('email_list').insert([{ email }]);

      if (error) {
        console.error('Error adding email:', error.message);
        setMessage('This email might already be on the list.');
      } else {
        setMessage('Email successfully added to the list!');
        setEmailSubmitted(true);
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('Failed to add email. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-gradient-to-r from-blue-500 to-purple-500 p-6 rounded-lg text-center shadow-md max-w-5xl">
      <h2 className="text-3xl font-bold text-white mb-4">
        Get First Dibs on the Hottest Business Ideas
      </h2>
      <p className="text-white mb-6">
        When everyone else is hearing it, you’re already building it.
        <span className="hidden sm:inline">
          <br /> Get ahead of the crowd and unlock new ideas from top business podcasts before they go mainstream.
        </span>
      </p>
      <form onSubmit={handleSubmit} className="flex justify-center items-center space-x-4">
        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="input input-bordered w-full max-w-xs px-4 py-2 rounded"
          disabled={loading}
        />
        <button
          type="submit"
          disabled={loading}
          className="btn btn-accent text-white px-6 py-2 rounded-lg font-semibold shadow-lg hover:bg-opacity-90 transition duration-150"
        >
          {loading ? (
            <span className="loading loading-spinner"></span>
          ) : (
            <span className="sm:inline hidden">Be First to Know</span>
          )}
          <span className="sm:hidden">Go</span>
        </button>
      </form>
      {message && <p className="text-white mt-4">{message}</p>}
      <div className="text-sm text-white mt-6">
        By signing up, you agree to our{' '}
        <Link to="/privacy" className="underline hover:text-gray-200">
          Privacy Policy
        </Link>{' '}
        and{' '}
        <Link to="/terms" className="underline hover:text-gray-200">
          Terms of Service
        </Link>.
      </div>
    </div>
  );
};

export default EmailCapture;
