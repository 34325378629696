import React from 'react';

const Privacy = () => {
  return (
    <div className="p-6 bg-base-100 text-base-content rounded-lg shadow-lg">
      <h1 className="text-2xl font-bold mb-4">Privacy Policy for Ideas Inbound</h1>
      <p className="mb-2">*Effective Date: November 13, 2024*</p>
      <p className="mb-4">
        Thank you for visiting Ideas Inbound (http://ideasinbound.com/). We value your privacy and are committed to
        protecting your personal information. This Privacy Policy outlines how we collect, use, and protect the
        information you provide to us.
      </p>
      
      <h2 className="text-lg font-semibold mb-2">1. Information We Collect</h2>
      <p className="mb-4">
        We collect personal information that you voluntarily provide, including:
        <ul className="list-disc list-inside pl-4">
          <li>Name</li>
          <li>Email</li>
          <li>Payment Information</li>
        </ul>
        Additionally, we may collect non-personal data through cookies to enhance your experience on our site.
      </p>
      
      <h2 className="text-lg font-semibold mb-2">2. Purpose of Data Collection</h2>
      <p className="mb-4">
        We collect personal information for the purposes of:
        <ul className="list-disc list-inside pl-4">
          <li>Signing up users for our service</li>
          <li>Processing orders</li>
        </ul>
      </p>
      
      <h2 className="text-lg font-semibold mb-2">3. Data Sharing</h2>
      <p className="mb-4">We do not share your personal data with any other parties.</p>
      
      <h2 className="text-lg font-semibold mb-2">4. Children’s Privacy</h2>
      <p className="mb-4">
        We do not knowingly collect or solicit personal information from children. If we become aware of any such data,
        we will promptly delete it.
      </p>
      
      <h2 className="text-lg font-semibold mb-2">5. Updates to This Policy</h2>
      <p className="mb-4">We may occasionally update this Privacy Policy. When we do, we will notify users by email.</p>
      
      <h2 className="text-lg font-semibold mb-2">6. Contact Information</h2>
      <p className="mb-4">
        If you have any questions about this Privacy Policy, please contact us at <a href="mailto:40willobrien@gmail.com" className="text-primary underline">40willobrien@gmail.com</a>.
      </p>
      
      <p>Thank you for trusting Ideas Inbound with your information.</p>
    </div>
  );
};

export default Privacy;
